<template>
  <div>
    <v-container fluid>
      <v-row class="pd0">
        <v-col lg="7">
          <div class="inside-bg">&nbsp;</div>
        </v-col>
        <v-col lg="5">
          <div class="logo-img">
            <img
              class="img-fluid"
              lazy-src="../assets/logo.png"
              src="../assets/logo.png"
            />
          </div>
        </v-col>
      </v-row>
      <v-row fill-height class="mb30">
        <v-col>
          <v-card class="mx-auto p40 flexcard">
            <v-card-text class="grow">
              <h3 class="headline text-center">
                Alege piesa potrivită pentru a întregi capodopera lui Sandro
                Botticelli (click variante)
              </h3>
              <v-form v-model="valid" class="box-special-radio">
                <v-radio-group>
                  <v-row>
                    <v-col lg="3" md="12" class="col-left col-special">
                      <v-radio value="a" @change="onChange('a')">
                        <div slot="label">
                          <img
                            :lazy-src="this.image1"
                            :src="this.image1"
                            class="img-responsive"
                            width="150"
                          />
                        </div>
                      </v-radio>
                      <v-radio
                        value="b"
                        class="good-item"
                        v-bind:class="{ blink_image: isActive }"
                        @change="onChange('b')"
                      >
                        <div slot="label">
                          <img
                            :lazy-src="this.image2"
                            :src="this.image2"
                            class="img-responsive"
                            width="150"
                          />
                        </div>
                      </v-radio>
                    </v-col>
                    <v-col lg="6" md="12">
                      <div class="image-container">
                        <div
                          class="image-text"
                          v-bind:class="{
                            visible: withtext != false,
                          }"
                        >
                          <div class="image-inner-text">
                            <h3>{{ message }}</h3>
                            <p>
                              Venus reprezintă evoluția frumosului în arta
                              clasică. O înfățișare impecabilă, fără cusur. În
                              medicina de astăzi, SKYRIZI ® este asociat cu
                              răspunsul DURABIL și EFICIENT<sup>1,2</sup> atunci
                              când vine vorba de psoriazis sau leziunile de
                              psoriazis de la nivelul pielii.
                            </p>
                            <a href="/quiz-5" class="link-btn"
                              >Următoarea întrebare
                            </a>
                          </div>
                        </div>
                        <img
                          class="inner-image img-fluid"
                          :lazy-src="this.image"
                          :src="this.image"
                        /></div
                    ></v-col>
                    <v-col lg="3" md="12" class="col-right col-special">
                      <v-radio value="c" @change="onChange('c')">
                        <div slot="label">
                          <img
                            :lazy-src="this.image3"
                            :src="this.image3"
                            class="img-responsive"
                            width="150"
                          />
                        </div>
                      </v-radio>
                      <v-radio value="d" @change="onChange('d')">
                        <div slot="label">
                          <img
                            :lazy-src="this.image4"
                            :src="this.image4"
                            class="img-responsive"
                            width="150"
                          />
                        </div>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fixed-bottom pd0">
        <v-col lg="5">
          <div class="logo-img-bottom">
            <img
              class="img-fluid"
              lazy-src="../assets/abbvie-logo.png"
              src="../assets/abbvie-logo.png"
            />
          </div>
        </v-col>
        <v-col lg="7">
          <div class="bottom-bg">&nbsp;</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "QuizD",
  data() {
    return {
      valid: true,
      message: "Upsss! Raspuns incorect!",
      withtext: false,
      isActive: false,
      image: require("@/assets/venus.png"),
      image1: require("@/assets/venus-1.png"),
      image2: require("@/assets/venus-2.png"),
      image3: require("@/assets/venus-3.png"),
      image4: require("@/assets/venus-4.png"),
    };
  },
  methods: {
    onChange(value) {
      var data = value;

      if (data != "b") {
        this.withtext = false;
        this.isActive = true;
        this.image2 = require("@/assets/venus-2-cover.png");
      } else {
        this.message = "Felicitări! Răspuns corect.";
        this.isActive = false;
        setTimeout(
          function () {
            this.withtext = true;
          }.bind(this),
          1500
        );
      }
      if (data === "a") {
        this.image1 = require("@/assets/venus-1-cover.png");
      } else {
        this.image1 = require("@/assets/venus-1.png");
      }
      if (data === "b") {
        this.image = require("@/assets/venus-full.png");
        this.image2 = require("@/assets/venus-2-cover.png");
      }
      if (data === "c") {
        this.image3 = require("@/assets/venus-3-cover.png");
      } else {
        this.image3 = require("@/assets/venus-3.png");
      }
      if (data === "d") {
        this.image4 = require("@/assets/venus-4-cover.png");
      } else {
        this.image4 = require("@/assets/venus-4.png");
      }
    },
  },
};
</script>
<style scoped>
.inside-bg {
  background: url(../assets/top-bg-intern.png) no-repeat center;
  background-size: 100%;
  background-position: top left;
  max-height: 450px;
  height: 450px;
}
.bottom-bg {
  background: url(../assets/bottom-bg.png) no-repeat center;
  background-size: 100%;
  background-position: bottom right;
  max-height: 220px;
  height: 210px;
}
.logo-img {
  float: right;
  margin-right: 50px;
}
.logo-img-bottom {
  margin-left: 100px;
}
.clear-both {
  clear: both;
}
.v-input--selection-controls .v-radio.v-item--active > .v-label {
  background: #af2a64 !important;
}
.v-input--selection-controls .v-radio.v-item--active.good-item > .v-label {
  background: #2896ce;
}
</style>
