<template>
  <div>
    <v-container fluid>
      <v-row class="pd0">
        <v-col lg="7">
          <div class="inside-bg">&nbsp;</div>
        </v-col>
        <v-col lg="5">
          <div class="logo-img">
            <img
              class="img-fluid"
              lazy-src="../assets/logo.png"
              src="../assets/logo.png"
            />
          </div>
        </v-col>
      </v-row>
      <v-row fill-height class="mb30">
        <v-col>
          <v-card class="mx-auto p40 flexcard" max-width="1200">
            <v-card-text class="grow">
              <h3 class="headline text-center" style="line-height: 3rem">
                Pentru a vedea Rezumatul<br />
                Caracateristicilor Produsului și<br />
                referințele accesați butonul de mai jos!
              </h3>
              <div style="text-align: center; padding-top: 30px">
                <a
                  href="img/2021_05_07__Quiz_Skyrizi_RCP.pdf"
                  target="_blank"
                  style="
                    padding: 20px 50px;
                    font-size: 3rem;
                    background: #2896ce;
                    color: #fff;
                    text-decoration: none;
                    display: inline-block;
                  "
                  >RCP</a
                >
                <span style="display: inline-block; width: 40px">&nbsp;</span>
                <a
                  href="/referinte"
                  target="_blank"
                  style="
                    padding: 20px 50px;
                    font-size: 3rem;
                    background: #2896ce;
                    color: #fff;
                    text-decoration: none;
                    display: inline-block;
                  "
                  >Referințe</a
                >
                <br />
                <a
                  href="/"
                  style="
                    padding: 10px 30px;
                    margin-top: 30px;
                    font-size: 1rem;
                    background: #ae2a63;
                    color: #fff;
                    text-decoration: none;
                    display: inline-block;
                  "
                  >Restart Joc</a
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fixed-bottom pd0">
        <v-col lg="12">
          <div class="bottom-bg">
            <div class="logo-img-bottom">
              <img
                class="img-fluid"
                lazy-src="../assets/abbvie-logo.png"
                src="../assets/abbvie-logo.png"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "Endpage",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped>
.inside-bg {
  background: url(../assets/top-bg-intern.png) no-repeat center;
  background-size: 100%;
  background-position: top left;
  max-height: 400px;
  height: 230px;
}
.bottom-bg {
  background: url(../assets/bottom-bg-end.png) no-repeat center;

  background-position: bottom right;
  max-height: 200px;
  height: 200px;
}
.logo-img {
  float: right;
  margin-right: 50px;
}
.logo-img-bottom {
  margin-left: 100px;
  padding-top: 105px;
}
.clear-both {
  clear: both;
}
.v-input--selection-controls .v-radio.v-item--active > .v-label {
  background: #af2a64 !important;
}
.v-input--selection-controls .v-radio.v-item--active.good-item > .v-label {
  background: #2896ce;
}
</style>
