<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default { name: "App", data: () => ({}) };
</script>
<style>
.inner-image {
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
.image-container .img-fluid {
  width: 100%;
}
.flexcard {
  display: flex;
  flex-direction: column;
  padding: 0px 100px 0px 100px;
}
.v-application h3.headline {
  color: #b1b1b1;
  font-size: 2rem !important;
  margin-bottom: 40px;
}
#app .v-radio {
  height: 30%;
}
#app .box-special-radio .v-radio {
  height: auto;
}

#app .v-input--selection-controls .v-radio > .v-label {
  width: 100%;
  display: block;
  padding: 30px;
  background: #b1b1b1;
  color: #fff;
  font-size: 2em;
  line-height: 1;
  margin-top: 50px;

  min-height: 70%;
}
.v-input--selection-controls .v-radio.v-item--active > .v-label {
  background: #af2a64 !important;
}
.v-input--selection-controls .v-radio.good-item.v-item--active > .v-label {
  background: #2896ce !important;
}
#app .box-special-radio .v-input--selection-controls .v-radio > .v-label {
  width: auto;
  display: inline-block;
  padding: 0px;
  background: #fff;
  color: #fff;
  font-size: 1.6rem;
  margin-top: 50px;
}
#app
  .box-special-radio
  .v-input--selection-controls
  .v-radio.v-item--active
  > .v-label {
  background: transparent !important;
}
#app
  .box-special-radio
  .v-input--selection-controls
  .v-radio.good-item.v-item--active
  > .v-label {
  background: transparent !important;
}
.col-special {
  position: relative;
}
.col-left > .v-radio:first-child {
  position: absolute;
  top: 0;
}
.col-left > .v-radio:last-child {
  position: absolute;
  top: 189px;
  left: 139px;
}
.col-right > .v-radio:first-child {
  position: absolute;
  top: 0;
}
.col-right > .v-radio:last-child {
  position: absolute;
  top: 189px;
  left: 139px;
}
.v-radio .v-input--selection-controls__input {
  display: none;
}
.image-container {
  position: relative;
}
.image-text {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.9);
}
.image-text.visible {
  display: block;
}
.image-text .image-inner-text {
  text-align: center;
  width: 90%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
.image-text .image-inner-text p {
  font-size: 1.3rem;
  color: #666;
  line-height: 2rem;
}
.image-text .image-inner-text h3 {
  font-size: 2rem;
  color: #2896ce;
  margin-bottom: 30px;
}
.link-btn {
  text-decoration: none;
  padding: 10px 10px 10px 50px;
  background-image: url(assets/play-icon.png);
  background-position: left center;
  background-repeat: no-repeat;
  color: #2896ce !important;
  font-size: 1.3rem;
  font-weight: bold;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.pd0 .col {
  padding: 0 !important;
}
.mb30 {
  margin-bottom: 30px;
}
.fixed-bottom {
  bottom: 0;
  width: 100%;
  height: 220px;
  margin-left: 0 !important;
  position:fixed;
}
.v-input--selection-controls .v-radio.good-item.blink_bg > .v-label {
  animation: blinker 0.8s linear infinite;
  background: #2896ce !important;
}
.v-input--selection-controls .v-radio.good-item.blink_image > .v-label img {
  animation: blinkerimg 0.8s linear infinite;
}
@keyframes blinker {
  10% {
    opacity: 0.6;
  }
}

@keyframes blinkerimg {
  10% {
    opacity: 0.6;
  }
}
.imp-text {
  position: absolute;
  bottom: -70px;
  width: calc(100vw - 90%);
  font-size: 12px;
}
.logo-img-bottom {
  margin-left: 140px !important;
  position: absolute;
  bottom: 50px;
}
.logo-img-bottom > img {
  max-height: 40px;
}
</style>
