<template>
  <div>
    <v-container fluid>
      <v-row class="pd0">
        <v-col lg="7">
          <div class="inside-bg">&nbsp;</div>
        </v-col>
        <v-col lg="5">
          <div class="logo-img">
            <img 
            class="img-fluid" 
            lazy-src="../assets/logo.png"
            src="../assets/logo.png" 
            />
          </div>
        </v-col>
      </v-row>
      <v-row fill-height class="mb30">
        <v-col>
          <v-card class="mx-auto p40 flexcard">
            <v-card-text class="grow">
              <h3 class="headline text-center">
                Din ce capodoperă a lui Leonardo Da vinci face parte piesa de
                puzzle?
              </h3>
              <v-form v-model="valid">
                <v-radio-group>
                  <v-row>
                    <v-col lg="3" md="12">
                      <v-radio
                        label="A. Judecata de apoi"
                        value="a"
                        @change="onChange('a')"
                      >
                      </v-radio>
                      <v-radio
                        label="B. Mona Lisa"
                        value="b"
                        class="good-item"
                        v-bind:class="{ blink_bg: isActive }"
                        @change="onChange('b')"
                      >
                      </v-radio>
                    </v-col>
                    <v-col lg="6" md="12">
                      <div class="image-container">
                        <div
                          class="image-text"
                          v-bind:class="{
                            visible: withtext != false,
                          }"
                        >
                          <div class="image-inner-text">
                            <h3>{{ message }}</h3>
                            <p>
                              Este suficient ca o singură bucățică să lipsească
                              și tot tabloul își pierde valoarea. SKYRIZI® este
                              în mâna medicilor piesa importantă care le oferă
                              pacienților un tabloul CURAT și COMPLET
                              <sup>1,2</sup> al pielii lor.
                            </p>
                            <a href="/quiz-3" class="link-btn"
                              >Următoarea întrebare
                            </a>
                          </div>
                        </div>
                        <img
                          class="inner-image img-fluid"
                          :lazy-src="this.image"
                          :src="this.image"
                        /></div
                    ></v-col>
                    <v-col lg="3" md="12">
                      <v-radio
                        label="C. Creația"
                        value="c"
                        @change="onChange('c')"
                      >
                      </v-radio>
                      <v-radio
                        label="D. Cina cea de taină"
                        value="d"
                        @change="onChange('d')"
                      >
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="fixed-bottom pd0">
        <v-col lg="5">
          <div class="logo-img-bottom">
            <img
              class="img-fluid"
              lazy-src="../assets/abbvie-logo.png"
              src="../assets/abbvie-logo.png"
            />
          </div>
        </v-col>
        <v-col lg="7">
          <div class="bottom-bg">&nbsp;</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "QuizB",
  data() {
    return {
      valid: true,
      message: "Upsss! Raspuns incorect!",
      withtext: false,
      image: require("@/assets/quiz-b-1.jpg"),
      isActive: false,
    };
  },
  methods: {
    onChange(value) {
      var data = value;

      if (data != "b") {
        this.isActive = true;
        this.withtext = false;
      } else {
        this.isActive = false;
        this.message = "Răspunsul este corect!";
        this.image = require("@/assets/quiz-b-2.jpg");
        setTimeout(
          function () {
            this.withtext = true;
          }.bind(this),
          1500
        );
      }
    },
  },
};
</script>
<style scoped>
.inside-bg {
  background: url(../assets/top-bg-intern.png) no-repeat center;
  background-size: 100%;
  background-position: top left;
  max-height: 450px;
  height: 450px;
}
.bottom-bg {
  background: url(../assets/bottom-bg.png) no-repeat center;
  background-size: 100%;
  background-position: bottom right;
  max-height: 220px;
  height: 210px;
}
.logo-img {
  float: right;
  margin-right: 50px;
}
.logo-img-bottom {
  margin-left: 100px;
}
.clear-both {
  clear: both;
}
.v-input--selection-controls .v-radio.v-item--active > .v-label {
  background: #af2a64 !important;
}
.v-input--selection-controls .v-radio.v-item--active.good-item > .v-label {
  background: #2896ce;
}
</style>
